import * as React from 'react'
import Nav from "../components/nav";
import Layout from "../components/layout"
import HeroImage from "../images/hero-image.webp"
import HeroImageM from "../images/hero-image - m.webp"
import AreasWeHelp from "../components/AreasWeHelp";
import Arrow from "../images/arrow-right-white.svg"
import WorkExamples from "../components/work-examples";
import AboutUs from "../components/About-us";
import Footer from "../components/footer";
import YoutubeThumb from "../images/youtube-thumb.webp"
import WorkExamplesNew from "../components/work-examples-new";
import SocialMedia from "../components/social-media";
import Reviews from "../components/reviews";
import {GatsbyImage, StaticImage} from "gatsby-plugin-image"
import UpdatedReviews from "../components/updated-reviews";
import {useEffect} from "react";
import {Helmet} from "react-helmet";

//npx gatsby develop

function IndexPage() {
    let loaded = false;

    useEffect(() => {
        window.addEventListener('scroll', loadMore);
    });

    function loadMore(){
        if (loaded === false){
            window.removeEventListener('scroll', loadMore);

            const script = document.createElement("script");

            script.src = "https://apps.elfsight.com/p/platform.js";
            script.async = true;

            document.body.appendChild(script);
            loaded = true
        }



    }



    const Holder = "<a href=https://www.youtube.com/embed/Qt_hjeiJScw><img class='w-full' src=" + YoutubeThumb + "></a>";


    return (
        <Layout>
            <Helmet>
                <title>Kinetic Impact | Sports Injury & Rehab Specialist</title>

            </Helmet>
            <main>
                <Nav home={true}/>

                <img className={"lg:block absolute hidden object-cover w-full h-[1000px] lg:h-[1300px] -z-50  transition duration-500"}
                     src={HeroImage} alt={"hero image"}/>
                <img  className={"lg:hidden block absolute object-cover w-full h-[1000px] lg:h-[1300px] -z-50  transition duration-500"}
                      src={HeroImageM} alt={"hero image"}/>
                <div className={"h-full flex flex-col justify-center items-center pl-6 pt-2 mx-auto"}>
                    <div className={"text-white lg:w-[64rem] lg:pt-24 lg:pb-24 pt-10 pr-8 lg:pr-0"}>
                        <h1 className={"lg:text-7xl text-5xl lg:max-w-xl leading-[1.1]"}>Live with Confidence. Move without
                            limits.</h1>
                        <p className={"max-w-sm text-xl py-8"}>No more sitting on the sidelines watching everyone else
                            have fun. Learn how to train to get back in the game!</p>
                        <a href={"http://kineticimpact.janeapp.com/"} className={"bg-accent text-black px-7 py-4 text-xl hover:bg-gray-100 group"}> Book free discovery visit <img  loading={"eager"} className={"inline pl-2 group-hover:translate-x-3 duration-150"} src={Arrow} alt={"arrow"}/>
                        </a>
                        <a className={"lg:hidden"} href={"https://www.youtube.com/watch?v=Qt_hjeiJScw"}>
                        <img className={"pt-10 pointer"}  id={"Mobile-video-clicker"} alt={"youtube thumbnail"}  src={YoutubeThumb}/>
                        </a>


                        <div className={" block lg:hidden my-8 pl-8 lg:border-l-2 lg:border-l-accent lg:bg-auto bg-accent text-black lg:text-white max-w-sm"}>
                            <p className={"font-bold lg:pt-0 pt-6"}>We are not a quick fix or short term solution.</p>
                            <p className={"p-0 lg-pb-0 pb-6"}>We help strong people get stronger, fast people get faster and injured
                                people overcome fears and frustrations so they can move without limitations.</p>
                        </div>
                        <div className={"hidden lg:block my-8 pl-8 border-l-2 border-l-accent bg-auto text-white max-w-sm"}>
                            <p className={"font-bold lg:pt-0 pt-6"}>We are not a quick fix or short term solution.</p>
                            <p className={"p-0 lg:pb-0 pb-6"}>We help strong people get stronger, fast people get faster and injured
                                people overcome fears and frustrations so they can move without limitations.</p>
                        </div>
                        <iframe className={"hidden lg:block lg:w-[800px] lg:h-[449px] w-3/4 h-full"}
                            scrolling="no"
                            width="800"
                            height="449"
                            src="https://www.youtube.com/embed/Qt_hjeiJScw"
                            srcDoc={Holder}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Kinetic Impact"
                        ></iframe>

                    </div>

                </div>
                <AreasWeHelp/>
                <WorkExamples/>

                    <div className="elfsight-app-629f5277-cbac-4eb2-83ea-645441593368"></div>

                <div className={"py-12"}>
                    <h1 className="lg:text-5xl md:text-4xl text-2xl font-semibold px-4 leading-10 text-gray-800 mt-6 text-center py-12">What our client says</h1>
                    <div className="elfsight-app-2e65ef09-fcec-436a-a93e-212e44a9d122"></div>

                    {/*<Reviews/>*/}
                </div>


                <Footer/>

            </main>
        </Layout>
    )
}


export default IndexPage